import {Map, Placemark, YMaps} from "react-yandex-maps";
import React from "react";

type Item = {
    x: number
    y: number
}

interface Props {
    items: Item[]
    onMapClick: (e: any) => void
    initFocus: Item
}

const FacilityMap = ({ items, onMapClick, initFocus }: Props) => {
    return (
        <YMaps>
            <Map
                // modules={['geoObject.addon.editor']}
                defaultState={{ center: [initFocus.x, initFocus.y], zoom: 9 }}
                // options={[]}
                width={'100%'}
                height={'25em'}
                onClick={onMapClick}
            >
                {
                    items.map((item, key) => (
                        <Placemark key={key} geometry={[item.x, item.y]} />
                    ))
                }
            </Map>
        </YMaps>
    )
}

export default FacilityMap
