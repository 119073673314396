import { ICountryFull } from "@app/types/geoGuidTypes"
import { Box, Divider, Collapse, FormControl, FormControlLabel, FormLabel, FormGroup } from "@mui/material"
import { FC } from "react"
import Item from "./Item"

interface IProps {
	areas?: ICountryFull["cities"][0]['areas']
    selectedAreas: number[]
}

const AreaFilterComponent: FC<IProps> = ({ areas, selectedAreas }) => {
	return (
		<Collapse in={!!areas?.length}>
			<div>
				<Box my={4}>
					<Divider />
				</Box>
				<FormControl component="fieldset">
					<FormLabel component="legend">Выберите районы</FormLabel>
					<FormGroup>
						{areas?.map(({ id, title }) => (
							<FormControlLabel
								key={`areas-filter_${id}`}
								value={id}
								control={<Item id={id} selectedAreas={selectedAreas} />}
								label={title}
							/>
						))}
					</FormGroup>
				</FormControl>
			</div>
		</Collapse>
	)
}

export default AreaFilterComponent