import {DeliveryDate} from "@app/types/filter";
import {ChangeEvent, Fragment, useMemo} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, Divider, FormControlLabel} from "@mui/material";
import {useSelector} from "../../store";

interface Props {
    year: number
    quarters: DeliveryDate[]
    checkedValues: DeliveryDate[]
    setChecked: (dates: DeliveryDate[]) => void
}

const YearAccordion = ({ year, quarters, setChecked, checkedValues }: Props) => {
    const { value: filters } = useSelector(state => state.filters)

    const fullYearChecked = useMemo(() => {
        const yearChecked = checkedValues.filter(d => d.year === year)
        return yearChecked.length === quarters.length
    }, [year, quarters, checkedValues])

    const yearCheckboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget

        if (checked) {
            setChecked([ ...checkedValues, ...quarters ])
        } else {
            setChecked(checkedValues.filter(d => d.year !== year))
        }
    }
    const quarterCheckboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { value: selectedQuarter, checked } = e.currentTarget

        if (checked) {
            setChecked([ ...checkedValues, { year, quarter: selectedQuarter } ])
        } else {
            setChecked(checkedValues.filter(d => !(d.year === year && d.quarter === selectedQuarter)))
        }
    }

    if (!filters) return null

    return (
        <Accordion
            sx={{boxShadow: 'none', border: 0}}
        >
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={
                    {
                        margin: 0,
                        padding: 0,
                        height: 20,
                        border: 0
                    }
                }

            >
                <div>
                    <Checkbox
                        value={year}
                        onChange={yearCheckboxHandler}
                        checked={fullYearChecked}
                    />
                    <span>{year}</span>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {
                    quarters.map((quarter, i) => (
                        <Fragment key={i}>
                            <FormControlLabel
                                sx={{
                                    pl: '20px',
                                    pt: 0,
                                    mt: 0
                                }}
                                control={
                                    <Checkbox
                                        value={quarter.quarter}
                                        onChange={quarterCheckboxHandler}
                                    />
                                }
                                label={quarter.quarter}
                                checked={checkedValues.findIndex(d => d.year === quarter.year && d.quarter === quarter.quarter) !== -1}
                            />
                        </Fragment>
                    ))
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default YearAccordion
