export enum FilterPageEnum {
    PRICE,
    SQUARE,
    DELIVERY_DATES,
    PRICE_PER_METER,
    DISTANCE_TO_THE_BEACH,
    BENEFITS,
    DECORATIONS,
    REGISTRATIONS,
    PAYMENT_OPTIONS,
    FACILITY_TYPES,
    DISTRICTS,
    MICRO_DISTRICTS,
    HOUSE_CLASSES,
    GEO
}
