import { ICountryFull } from "@app/types/geoGuidTypes"
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { useDispatch } from "react-redux"
import { setCountryId } from "src/slices/filters"

interface IProps {
    countries: ICountryFull[]
    value?: number|string
}

const CountryFilterComponent: FC<IProps> = ({ countries, value }) => {
	const dispatch = useDispatch()

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		if (isNaN(+event.target.value)) {
			dispatch(setCountryId(undefined))
		}
		dispatch(setCountryId(+event.target.value))
	}

	return (
		<FormControl component="fieldset">
			<FormLabel component="legend">Выберите страну</FormLabel>
			<RadioGroup name="country" value={value} onChange={handleChange}>
				{countries?.map(({ id, title }) => (
					<FormControlLabel key={`country-filter_${id}`} value={id} control={<Radio />} label={title} />
				))}
				<Button onClick={() => dispatch(setCountryId(undefined))}>Сбросить страну</Button>
			</RadioGroup>
		</FormControl>
	)
}

export default CountryFilterComponent