import { Checkbox } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { useDispatch } from "react-redux"
import { setAreasIds } from "src/slices/filters"

interface IProps {
    id: number
    selectedAreas: number[]
}

const Item: FC<IProps> = ({ id, selectedAreas }) => {
    const checked = selectedAreas.includes(id)
    const dispatch = useDispatch()

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
		if ((event.target.checked)) {
            dispatch(setAreasIds([...selectedAreas, id]))
		} else {
            dispatch(setAreasIds([...selectedAreas].filter(item => item !== id)))
        }
	}

    return <Checkbox color="primary" checked={checked} onChange={handleChange} />
}

export default Item