import {DeliveryDate} from "@app/types/filter";
import {Fragment, useEffect, useMemo, useState} from "react";
import {groupBy} from "../../utils/group-by";
import {
    Box, Button,
    Checkbox,
    Divider,
    Grid
} from "@mui/material";
import YearAccordion from "@components/filter/YearAccordion";

interface Props {
    values: DeliveryDate[]
    defaultChecked: DeliveryDate[]
    onSave?: (dates: DeliveryDate[]) => any
}

const DeliveryDatesFilter = ({ values, defaultChecked, onSave }: Props) => {
    const [checked, setChecked] = useState<DeliveryDate[]>([])

    const grouped = useMemo(() => groupBy(values, v => v.year), [values])

    useEffect(() => {
        setChecked(defaultChecked)
    }, [defaultChecked])

    return (
        <>
            <Box>
                {
                    Object.keys(grouped).map((year, i) => (
                        <Fragment key={i}>
                            <YearAccordion
                                year={parseInt(year)}
                                quarters={grouped[year]}
                                checkedValues={checked}
                                setChecked={setChecked}
                            />
                            <Divider sx={{ mt: 1 }} />
                        </Fragment>
                    ))
                }
            </Box>
            <Box sx={{ mt: 3 }}>
                <Grid
                    container
                    spacing={10}
                >
                    <Grid item md={3} xs={12}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => onSave(checked)}
                        >
                            Применить
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Button
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => onSave([])}
                        >
                            Очистить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default DeliveryDatesFilter
