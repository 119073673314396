import { RootState } from "../store/index"
import { createSelector } from "@reduxjs/toolkit"

export const availFilterAreasSelector = createSelector(
	(state: RootState) => state.filters.value,
	(filters) => {
		const countries = filters?.all.countries
		const selectedCountryId = filters?.filtered.countryId
		const selectedCityId = filters?.filtered.cityId

		if (!countries || !selectedCountryId || !selectedCityId) {
			return
		}

		const cities = countries.find(({ id }) => id === selectedCountryId)?.cities

		if ( !cities ) {
			return
		}

		return cities.find(({ id }) => id === selectedCityId)?.areas
	}
)
