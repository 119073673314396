import {Box, Button, Divider, Grid, TextField} from "@mui/material";
import CheckboxBlock from "@components/checkbox/checkbox";
import {useEffect, useMemo, useState} from "react";
import {useDispatch} from "../../store";

type Value = {
    label: string
    value: string
}

interface Props {
    values: Value[]
    checked: Value[]
    onSave?: (data: string[]) => any
    isSearch?: boolean
}

const CheckboxFilter = ({ onSave, values, checked, isSearch = false }: Props) => {
    const initCheckedValues = useMemo<string[]>(() => checked.map(c => c.value), [checked])
    const [checkedValues, setCheckedValues] = useState<string[]>(initCheckedValues)
    const [search, setSearch] = useState<string>('')

    const dispatch = useDispatch()

    useEffect(() => {
        setCheckedValues(initCheckedValues)
    }, [initCheckedValues])

    const filteredItems = useMemo<Value[]>(() => values.filter(v => v.label.toLowerCase().startsWith(search.toLowerCase())), [search, values])

    const handleClickCheckbox = (val: string) => {
        if (checkedValues.includes(val)) {
            setCheckedValues(prev => prev.filter(v => v !== val))
        } else {
            setCheckedValues(prev => [ ...prev, val ])
        }
    }

    const save = () => {
        // dispatch(onSave(checkedValues))
        onSave(checkedValues)
    }

    const clear = () => {
        onSave([])
    }

    return (
        <>
            {
                isSearch &&
                <Box  sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        label={'Поиск'}
                        placeholder={'Введите запрос'}
                    />
                    <Divider sx={{ mt: 2 }} />
                </Box>
            }
            <Box>
                {
                    filteredItems.map((v, i) => (
                        <CheckboxBlock
                            key={i}
                            label={v.label}
                            value={v.value}
                            checked={checkedValues.includes(v.value)}
                            onSelect={handleClickCheckbox}
                        />
                    ))
                }
            </Box>
            <Box sx={{ mt: 3 }}>
                <Grid
                    container
                    spacing={10}
                >
                    <Grid item md={3} xs={12}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            onClick={save}
                        >
                            Применить
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Button
                            color={'primary'}
                            variant={'outlined'}
                            onClick={clear}
                        >
                            Очистить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CheckboxFilter
