import {Filter} from "@app/types/filter";
import {useMemo} from "react";
import {Box, Chip} from "@mui/material";

interface Props {
    filters: Filter
    onClearFilter: (key: string) => void
    onClearAll: () => void
}

const translate = {
    districts: 'Район',
    microDistricts: 'Микрорайон',
    facilityTypes: 'Тип недвижимости',
    registrations: "Оформление",
    decorations: "Отделка квартир",
    paymentOptions: "Варианты оплаты",
    houseClasses: "Класс дома",
    benefits: "Преимущества",
    square: "Площадь",
    price: "Цена",
    pricePerMeter: "Цена за кв.м",
    deliveryDate: "Срок сдачи",
    deliveryDates: "Срок сдачи",
    distanceToTheBeach: "Расстояние до моря",
    isDelivered: 'Срок сдачи'
}

function getLine<T>(data: T, key: string): string | undefined {
    const type = typeof data
    if (type !== 'object') return
    if (Array.isArray(data)) {
        if (data['length'] === 0) return
        if (key === 'deliveryDates') return data.map(d => `${d['year']}-${d['quarter']}`).join(', ')
        return data.join(', ')
    } else if (data.hasOwnProperty('min') && data.hasOwnProperty('max')) {
        return `${data['min']} - ${data['max']}`
    } else return
}

const FiltersLine = ({ filters, onClearFilter, onClearAll }: Props) => {
    const keys = useMemo(() => Object.keys(filters), [filters])
    const lines = useMemo(() => {
        const obj = {}

        keys.forEach(k => {
            const line = getLine(filters[k], k)
            if (line) obj[k] = line
        })

        return obj
    }, [keys])
    const isFilters = useMemo(() => Object.keys(lines).length > 0, [lines])

    if (!isFilters) return null

    return (
        <Box sx={{ p: 2 }}>
            {
                Object.keys(lines).map((k, i) => {
                    if (!lines[k]) return

                    return (
                        <Chip
                            key={i}
                            color="primary"
                            onDelete={() => onClearFilter(k)}
                            label={`${translate[k]}: ${lines[k]}`}
                            sx={{ mr: 1 }}
                        />
                    )
                })
            }
            <Chip
                color="primary"
                variant={'outlined'}
                label={'Очистить все'}
                onClick={onClearAll}
            />
        </Box>
    )
}

export default FiltersLine
