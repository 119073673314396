import {ReactNode} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";

interface Props {
    children: ReactNode
    title: string
    desc: string
}

const FilterBlock = ({ title, desc, children }: Props) => {
    return (
        <Box>
            <Typography variant={'h6'}>{title}</Typography>
            <Typography>{desc}</Typography>

            <Box
                sx={{ mt: 3, mb: 3 }}
            >
                { children }
            </Box>
        </Box>
    )
}

export default FilterBlock
