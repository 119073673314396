import { Box, Card, Checkbox, Radio, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

interface Props<T> {
    label: string;
    value: T;
    checked: boolean;
    onSelect: (val: T) => void;
    name?: string;
}

const CheckboxBlock = <T,>({ label, value, checked, onSelect, name }: Props<T>) => {
    return (
        <Box key={String(value)} sx={{ mb: 2 }}>
            <Card
                key={String(value)}
                sx={{
                    alignItems: "center",
                    cursor: "pointer",
                    display: "flex",
                    p: 1,
                    ...(checked && {
                        borderColor: "primary.main",
                        borderWidth: 2,
                    }),
                }}
                onClick={(): void => onSelect(value)}
                variant="outlined"
            >
                <Checkbox checked={checked} color="primary" name={name} />
                <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle1">{label}</Typography>
                </Box>
            </Card>
        </Box>
    );
};

export default CheckboxBlock;
