import {useDispatch, useSelector} from "../../store";
import {Box, Chip} from "@mui/material";
import {FacilityCategory, FacilityCategoryNames} from "@app/types/facility";
import {setCategory} from "../../slices/filters";

const CategoryFilters = () => {
    const {value: filters} = useSelector(state => state.filters)
    const dispatch = useDispatch()

    if (!filters) return null

    const onClickCategory = (category: FacilityCategory) => {
        dispatch(setCategory(category))
    }

    return (
        <Box
            sx={{display: 'flex', gap: '0.8em', mt: 2}}
        >
            {
                Object.entries(FacilityCategoryNames).map(([category, label]) => (
                    <Chip
                        key={category}
                        label={label}
                        sx={{cursor: 'pointer'}}
                        onClick={() => onClickCategory(category as FacilityCategory)}
                        color={category === filters.category ? 'primary' : 'default'}
                    />
                ))
            }
        </Box>
    )
}

export default CategoryFilters
