import { useSelector } from "src/store"
import AreaFilterComponent from "./area"
import CityFilterComponent from "./city"
import CountryFilterComponent from "./country"
import { availFilterCitiesSelector } from "src/selectors/availFilterCitiesSelector"
import { availFilterAreasSelector } from "src/selectors/availFilterAreasSelector"
import { Box } from "@mui/system"

const GeoFilterComponent = () => {
    const { value: filters } = useSelector(state => state.filters)
    const cities = useSelector(availFilterCitiesSelector)
    const areas = useSelector(availFilterAreasSelector)

    if ( !filters ) {
        return null
    }

    const { countries } = filters.all
    const { areasIds, cityId, countryId } = filters.filtered

    return (
		<Box>
			<CountryFilterComponent countries={countries} value={countryId?.toString() || ""} />
			<CityFilterComponent cities={cities} value={cityId?.toString() || ""} />
			<AreaFilterComponent selectedAreas={areasIds || []} areas={areas} />
		</Box>
	)
}

export default GeoFilterComponent