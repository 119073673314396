import {Box, Button, Drawer, Grid, IconButton, Typography} from "@mui/material";
import {X as XIcon} from "../../icons/x";
import React, {useState} from "react";
import FilterPreview, {Item} from "@components/filter/FilterPreview";
import {useDispatch, useSelector} from "../../store";
import {
    clearByKey,
    removeDistricts, setBenefits, setDecorations, setDeliveryDates, setDistanceToTheBeach,
    setDistricts, setFacilityTypes, setHouseClasses,
    setMicroDistricts, setPaymentOptions, setPrice, setPricePerMeter,
    setRegistrations,
    setSquare, removeBenefit, removeDecoration, removeDeliveryDate, removeFacilityType, removeHouseClass, removeMicroDistrict, removeRegistration, removePaymentOption
} from "../../slices/filters";
import {FilterRange} from "@app/types/filter";
import SliderFilter from "@components/slider/Slider";
import {FilterPageEnum} from "@components/filter/FilterPageEnum";
import FilterPage from "@components/filter/FilterPage";
import CheckboxFilter from "@components/checkbox-filter/checkbox-filter";
import {toast} from "react-hot-toast";
import DeliveryDatesFilter from "@components/filter/DeliveryDatesFilter";
import { FacilityRegistration, FacilityHouseClass, FacilityPaymentOption, FacilityFlatDecoration, FacilityType, FacilityBenefit } from '@app/types/facility'
import GeoFilterComponent from "@components/filter/geoFilter";

interface Props {
    open: boolean
    onClose: () => void
    onAccept?: () => void
    onClear?: () => void
}

const drawerDefaultProps = {
    anchor: 'right',
    sx: { width: '30%' }
} as const

function rangeToItems<T>(range?: FilterRange<T>): Item[] {
    if (!range) return []
    return [
        {
            label: `${range.min} - ${range.max}`,
            value: `${range.min} - ${range.max}`,
        }
    ]
}

function rangeToSlider<T>(all: FilterRange<T>, filtered?: FilterRange<T>): T[] {
    if (!filtered || !(filtered.min && filtered.max)) return [all.min, all.max]
    return [filtered.min, filtered.max]
}

function arrayToItems(array: (string|number)[]): Item[] {
    return array.map(d => ({ value: d.toString(), label: d.toString() }))
}

const FiltersMenu = ({ open, onClose, onClear }: Props) => {
    const { value: filters } = useSelector(state => state.filters)
    const dispatch = useDispatch()

    const [activeFilter, setActiveFilter] = useState<FilterPageEnum>()

    const hideFilterPage = () => {
        setActiveFilter(undefined)
    }

    const saveFilter = (func: any, arg: any) => {
        dispatch(func(arg))
        hideFilterPage()
        toast.success('Фильтр применен')
    }

    if (!filters) return null

    return (
		<>
			<Drawer {...drawerDefaultProps} open={open} onClose={onClose}>
				<Box sx={{ p: 3, backgroundColor: "neutral.900" }}>
					<Grid container spacing={3} sx={{ alignItems: "center" }}>
						<Grid item md={6} xs={12}>
							<Typography sx={{ color: "primary.contrastText" }}>Фильтры</Typography>
						</Grid>
						<Grid item md={6} xs={12} sx={{ textAlign: "right" }}>
							<IconButton onClick={onClose}>
								<XIcon fontSize="small" />
							</IconButton>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ width: 500, p: 3 }} role="presentation">
					<Box sx={{ mt: 2 }}>
						<FilterPreview
							title={"Расположение"}
							type={FilterPageEnum.GEO}
							onClick={setActiveFilter}
							items={arrayToItems(filters.filtered.districts)}
							onClearItem={(val) => dispatch(removeDistricts([val]))}
						/>
						<FilterPreview
							type={FilterPageEnum.FACILITY_TYPES}
							onClick={setActiveFilter}
							title={"Тип недвижимости"}
							items={arrayToItems(filters.filtered.facilityTypes)}
							onClearItem={(val: FacilityType) => dispatch(removeFacilityType(val))}
						/>
						<FilterPreview
							type={FilterPageEnum.PRICE}
							onClick={setActiveFilter}
							title={"Цена"}
							items={rangeToItems(filters.filtered.price)}
							onClearItem={(val) => dispatch(clearByKey("price"))}
						/>
						<FilterPreview
							type={FilterPageEnum.SQUARE}
							onClick={setActiveFilter}
							title={"Площадь"}
							items={rangeToItems(filters.filtered.square)}
							onClearItem={(val) => dispatch(clearByKey("square"))}
						/>
						<FilterPreview
							type={FilterPageEnum.PRICE_PER_METER}
							onClick={setActiveFilter}
							title={"Цена за кв.м"}
							items={rangeToItems(filters.filtered.pricePerMeter)}
							onClearItem={(val) => dispatch(clearByKey("pricePerMeter"))}
						/>
						<FilterPreview
							type={FilterPageEnum.REGISTRATIONS}
							onClick={setActiveFilter}
							title={"Оформление"}
							items={arrayToItems(filters.filtered.registrations)}
							onClearItem={(val: FacilityRegistration) => dispatch(removeRegistration(val))}
						/>
						<FilterPreview
							type={FilterPageEnum.HOUSE_CLASSES}
							onClick={setActiveFilter}
							title={"Класс дома"}
							items={arrayToItems(filters.filtered.houseClasses)}
							onClearItem={(val: FacilityHouseClass) => dispatch(removeHouseClass(val))}
						/>
						<FilterPreview
							type={FilterPageEnum.DELIVERY_DATES}
							onClick={setActiveFilter}
							title={"Срок сдачи"}
							items={arrayToItems(filters.filtered.deliveryDates.map((d) => `${d.year}-${d.quarter}`))}
							onClearItem={(val: string) =>
								dispatch(
									removeDeliveryDate({
										year: parseInt(val.split("-")[0]),
										quarter: val.split("-")[1],
									})
								)
							}
						/>
						<FilterPreview
							type={FilterPageEnum.PAYMENT_OPTIONS}
							onClick={setActiveFilter}
							title={"Варианты оплаты"}
							items={arrayToItems(filters.filtered.paymentOptions)}
							onClearItem={(val: FacilityPaymentOption) => dispatch(removePaymentOption(val))}
						/>
						<FilterPreview
							type={FilterPageEnum.DECORATIONS}
							onClick={setActiveFilter}
							title={"Отделка квартир"}
							items={arrayToItems(filters.filtered.decorations)}
							onClearItem={(val: FacilityFlatDecoration) => dispatch(removeDecoration(val))}
						/>
						<FilterPreview
							type={FilterPageEnum.DISTANCE_TO_THE_BEACH}
							onClick={setActiveFilter}
							title={"Расстояние до моря"}
							items={rangeToItems(filters.filtered.distanceToTheBeach)}
							onClearItem={(val) => dispatch(clearByKey("distanceToTheBeach"))}
						/>
						<FilterPreview
							type={FilterPageEnum.BENEFITS}
							onClick={setActiveFilter}
							title={"Преимущества"}
							items={arrayToItems(filters.filtered.benefits)}
							onClearItem={(val: FacilityBenefit) => dispatch(removeBenefit(val))}
						/>
					</Box>
					<Box sx={{ mt: 3 }}>
						<Grid>
							<Button onClick={onClear} variant={"outlined"}>
								Очистить все
							</Button>
						</Grid>
					</Box>
				</Box>
			</Drawer>

			<FilterPage
				open={activeFilter === FilterPageEnum.GEO}
				onClose={hideFilterPage}
				title={"Расположение"}
				desc={"Выберите расположение"}
				func={setSquare}
				onSave={saveFilter}
			>
				<GeoFilterComponent />
			</FilterPage>

			{filters.all.square && (
				<FilterPage
					open={activeFilter === FilterPageEnum.SQUARE}
					onClose={hideFilterPage}
					title={"Площадь"}
					desc={"Выберите площадь"}
					func={setSquare}
					onSave={saveFilter}
				>
					<SliderFilter
						init={rangeToSlider(filters.all.square, filters.filtered.square)}
						min={filters.all.square.min}
						max={filters.all.square.max}
					/>
				</FilterPage>
			)}

			{filters.all.price && (
				<FilterPage
					open={activeFilter === FilterPageEnum.PRICE}
					onClose={hideFilterPage}
					title={"Цена"}
					desc={"Выберите цену"}
					func={setPrice}
					onSave={saveFilter}
				>
					<SliderFilter
						init={rangeToSlider(filters.all.price, filters.filtered.price)}
						min={filters.all.price.min}
						max={filters.all.price.max}
					/>
				</FilterPage>
			)}

			{filters.all.pricePerMeter && (
				<FilterPage
					open={activeFilter === FilterPageEnum.PRICE_PER_METER}
					onClose={hideFilterPage}
					title={"Цена за кв.м"}
					desc={"Выберите цену за кв.м"}
					func={setPricePerMeter}
					onSave={saveFilter}
				>
					<SliderFilter
						init={rangeToSlider(filters.all.pricePerMeter, filters.filtered.pricePerMeter)}
						min={filters.all.pricePerMeter.min}
						max={filters.all.pricePerMeter.max}
					/>
				</FilterPage>
			)}

			{filters.all.distanceToTheBeach && (
				<FilterPage
					open={activeFilter === FilterPageEnum.DISTANCE_TO_THE_BEACH}
					onClose={hideFilterPage}
					title={"Расстояние до моря"}
					desc={"Выберите расстояние до моря"}
					func={setDistanceToTheBeach}
					onSave={saveFilter}
				>
					<SliderFilter
						init={rangeToSlider(filters.all.distanceToTheBeach, filters.filtered.distanceToTheBeach)}
						min={filters.all.distanceToTheBeach.min}
						max={filters.all.distanceToTheBeach.max}
					/>
				</FilterPage>
			)}

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.DISTRICTS}
				title={"Район"}
				desc={"Выберите район"}
				onSave={saveFilter}
				func={setDistricts}
			>
				<CheckboxFilter isSearch values={arrayToItems(filters.all.districts)} checked={arrayToItems(filters.filtered.districts)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.MICRO_DISTRICTS}
				title={"Микрорайон"}
				desc={"Выберите микрорайон"}
				onSave={saveFilter}
				func={setMicroDistricts}
			>
				<CheckboxFilter isSearch values={arrayToItems(filters.all.microDistricts)} checked={arrayToItems(filters.filtered.microDistricts)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.FACILITY_TYPES}
				title={"Тип недвижимости"}
				desc={"Выберите тип недвижимости"}
				onSave={saveFilter}
				func={setFacilityTypes}
			>
				<CheckboxFilter values={arrayToItems(filters.all.facilityTypes)} checked={arrayToItems(filters.filtered.facilityTypes)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.REGISTRATIONS}
				title={"Оформление"}
				desc={"Выберите оформление"}
				onSave={saveFilter}
				func={setRegistrations}
			>
				<CheckboxFilter values={arrayToItems(filters.all.registrations)} checked={arrayToItems(filters.filtered.registrations)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.HOUSE_CLASSES}
				title={"Класс дома"}
				desc={"Выберите класс дома"}
				onSave={saveFilter}
				func={setHouseClasses}
			>
				<CheckboxFilter values={arrayToItems(filters.all.houseClasses)} checked={arrayToItems(filters.filtered.houseClasses)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.PAYMENT_OPTIONS}
				title={"Варианты оплаты"}
				desc={"Выберите варианты оплаты"}
				onSave={saveFilter}
				func={setPaymentOptions}
			>
				<CheckboxFilter values={arrayToItems(filters.all.paymentOptions)} checked={arrayToItems(filters.filtered.paymentOptions)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.BENEFITS}
				title={"Преимущества"}
				desc={"Выберите преимущества"}
				onSave={saveFilter}
				func={setBenefits}
			>
				<CheckboxFilter values={arrayToItems(filters.all.benefits)} checked={arrayToItems(filters.filtered.benefits)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.DECORATIONS}
				title={"Отделка квартир"}
				desc={"Выберите отделку квартир"}
				onSave={saveFilter}
				func={setDecorations}
			>
				<CheckboxFilter values={arrayToItems(filters.all.decorations)} checked={arrayToItems(filters.filtered.decorations)} />
			</FilterPage>

			<FilterPage
				onClose={hideFilterPage}
				open={activeFilter === FilterPageEnum.DELIVERY_DATES}
				title={"Срок сдачи"}
				desc={"Выберите срок сдачи"}
				onSave={saveFilter}
				func={setDeliveryDates}
			>
				<DeliveryDatesFilter values={filters.all.deliveryDates} defaultChecked={filters.filtered.deliveryDates} />
			</FilterPage>
		</>
	)
}

export default FiltersMenu
