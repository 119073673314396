import {Button, Chip} from "@mui/material";
import {CSSProperties} from "react";
import {ArrowDropDown} from "@mui/icons-material";
import {FilterPageEnum} from "@components/filter/FilterPageEnum";

export type Item =  {
    label: string
    value: string
}

interface Props {
    title: string
    type: FilterPageEnum
    items: Item[]
    onClearItem: (val: string) => void
    onClick: (type: FilterPageEnum) => void
}

const chipStyles: CSSProperties = {
    margin: '0.5em'
}

const FilterPreview = ({ title, onClearItem, items, type, onClick }: Props) => {
    return (
        <Button
            onClick={() => onClick(type)}
            style={{
                width: '100%',
                display: 'block',
                marginTop: '1em',
                textAlign: 'left',
                border: '1px solid #D1D5DB'
            }}
        >

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <div>
                    {
                        items.length > 0 ? items.map((item, ind) => (
                            <Chip
                                key={ind}
                                color="primary"
                                onDelete={() => onClearItem(item.value)}
                                label={item.label}
                                style={chipStyles}
                            />
                        )) : <span style={{ color: 'black' }}>{title}</span>
                    }
                </div>
                <ArrowDropDown style={{ transform: 'rotate(-90deg)' }} />
            </div>
        </Button>
    )
}

export default FilterPreview
