import React, {ReactElement, ReactNode} from "react";
import {Box, Drawer, Grid, IconButton, Typography} from "@mui/material";
import {X as XIcon} from "../../icons/x";
import FilterBlock from "@components/filter/FilterBlock";

interface Props {
    children: ReactElement
    onClose: () => void
    open: boolean
    title: string
    desc: string
    func: any
    onSave: <T>(f: (d: T) => any, data: T) => any
}

const FilterPage = ({ open, onClose, children, title, desc, onSave, func }: Props) => {
    const save = (data: any) => {
        onSave(func, data)
    }

    return (
        <Drawer
            anchor={'right'}
            sx={{ width: '30%' }}
            onClose={onClose}
            open={open}
        >
            <Box
                sx={{ p: 3, backgroundColor: 'neutral.900' }}
            >
                <Grid container spacing={3} sx={{ alignItems: 'center' }}>
                    <Grid item md={6} xs={12}>
                        <Typography sx={{ color: 'primary.contrastText' }}>
                            Фильтры
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ textAlign: 'right' }}>
                        <IconButton
                            onClick={onClose}
                        >
                            <XIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{ width: 500, p: 3 }}
                role="presentation"
            >
                <FilterBlock title={title} desc={desc}>
                    { React.cloneElement(children, { onSave: save }) }
                </FilterBlock>
            </Box>
        </Drawer>
    )
}

export default FilterPage
