import {Box, Button, Grid, Slider, TextField} from "@mui/material";
import {useState} from "react";


interface Props {
    init: number[]
    onSave?: (val: number[]) => void
    min: number
    max: number
}

const SliderFilter = ({ min, max, init, onSave }: Props) => {
    const [value, setValue] = useState<number[]>(init)

    const handleChangeInput = (val: string, index: number) => {
        const num = parseInt(val.replace(/\D/,''))
        const newValue = [ ...value ]
        newValue[index] = num
        setValue(newValue)
    }

    const handleChangeSlider = (e: Event, val: number[]) => {
        setValue(val)
    }

    return (
        <Box sx={{ mb: 2 }}>
            <Slider
                size="small"
                defaultValue={value}
                value={value}
                aria-label="Small"
                valueLabelDisplay="auto"
                min={min}
                max={max}
                onChange={handleChangeSlider}
            />
            <Grid
                container
                justifyContent="space-between"
                spacing={4}
            >
                <Grid item md={6} xs={12}>
                    <TextField
                        value={value[0]}
                        onChange={(e) => handleChangeInput(e.target.value, 0) }
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        value={value[1]}
                        onChange={(e) => handleChangeInput(e.target.value, 1) }
                    />
                </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
                <Grid
                    container
                    spacing={10}
                >
                    <Grid item md={3} xs={12}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => onSave(value)}
                        >
                            Применить
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Button
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => onSave([])}
                        >
                            Очистить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default SliderFilter
