import {NextPage} from "next";
import {AuthGuard} from "@components/authentication/auth-guard";
import {DashboardLayout} from "@components/dashboard/dashboard-layout";
import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useRef, useState} from "react";
import {Facility, FacilitySort, GetFacilitiesWithFilters} from "@app/types/facility";
import {useAppContext} from "@app/app-context";
import Head from "next/head";
import {Box} from "@mui/system";
import {Button, Card, CardContent, Container, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {Search as SearchIcon} from "../../icons/search";
import {RiFilterLine, RiFunctionLine, RiMap2Line} from "react-icons/ri";
import FacilityMap from "../../components/facilities/facility-map";
import FacilityList from "@components/facilities/facility-list";
import FiltersMenu from "@components/facilities/filters-menu";
import {filterResponseToParams} from "@app/types/filter";
import {useDispatch, useSelector} from "../../store";
import {clear, clearByKey, initFilters} from "../../slices/filters";
import FiltersLine from "@components/filter/FiltersLine";
import CategoryFilters from "@components/filter/CategoryFilters";

interface SortOption {
    label: string;
    value: FacilitySort;
}

const sortOptions: SortOption[] = [
    {
        label: 'По дате',
        value: 'pubdate'
    },
    {
        label: 'По уменьшению цены',
        value: 'pricedesc'
    },
    {
        label: 'По увеличению цены',
        value: 'priceasc'
    },
    {
        label: 'По названию',
        value: 'byname'
    }
];

const FacilitiesPage: NextPage = () => {
    const {api} = useAppContext()
    const [showMap, setShowMap] = useState<boolean>(false)
    const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false)
    const [total, setTotal] = useState<number>(0)
    const [limit, setLimit] = useState<number>(10)
    const [page, setPage] = useState<number>(0)
    const [sort, setSort] = useState<FacilitySort>(sortOptions[0].value);
    const [search, setSearch] = useState<string>('')
    const queryRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch()
    const startRef = useRef<HTMLDivElement|null>(null)

    const {value: filters, loading} = useSelector(state => state.filters)

    const [facilities, setFacilities] = useState<Facility[]>([])

    const getFacilities = () => {
        if (!filters) return
        const params = filterResponseToParams(filters.filtered)
        const getParams: GetFacilitiesWithFilters = {
            ...params,
            sort: sort,
            searchQuery: search,
            page: page + 1,
            limit: limit,
            category: filters.category
        }
        api.facility.getAll(getParams)
            .then(res => {
                setFacilities(res.data)
                setTotal(res.total)
            })
    }

    useEffect(() => {
        dispatch(initFilters())
    }, [])

    useEffect(() => {
        setPage(0)
    }, [limit])

    useEffect(() => {
        getFacilities()
    }, [sort, page, limit, filters, search])

    // useEffect(() => {
    //     startRef.current?.scrollIntoView({behavior: 'smooth'})
    // }, [page])

    // const handleQueryChange = (event: FormEvent<HTMLFormElement>): void => {
    // const handleQueryChange = (event: FormEvent<HTMLInputElement>): void => {
    //     event.preventDefault();
    //     const value = queryRef.current?.value
    //     if ( value ) {
    //         setSearch(value)
    //     }
    // };
    const handleQueryChange = (): void => {
        // event.preventDefault();
        const value = queryRef.current?.value
        if ( value ) {
            setSearch(value)
        }
    };

    const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSort(event.target.value as FacilitySort);
    };

    const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };

    const clearFilters = () => {
        dispatch(clear())
    }

    const clearFilterByKey = (key: string) => {
        dispatch(clearByKey(key))
    }

    if (!filters) return null

    return (
        <>
            <Head>
                <title>
                    Объекты
                </title>
            </Head>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{mb: 4}}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Grid item>
                                <Typography variant="h4">
                                    Объекты
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Card>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                                m: -1.5,
                                p: 3
                            }}
                        >
                            <Button
                                variant={'outlined'}
                                sx={{height: '4em', width: '4em'}}
                                onClick={() => setShowMap(prev => !prev)}
                            >
                                {
                                    showMap ? <RiFunctionLine size={24}/> : <RiMap2Line size={24}/>
                                }
                            </Button>
                            <Box
                                component="form"
                                // onSubmit={handleQueryChange}
                                sx={{
                                    flexGrow: 1,
                                    m: 1.5
                                }}
                            >
                                <TextField
                                    defaultValue=""
                                    fullWidth
                                    inputProps={{ref: queryRef}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small"/>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={handleQueryChange}
                                    placeholder="Поиск по объектам"
                                />
                            </Box>
                            <TextField
                                label="Сортировка"
                                name="sort"
                                onChange={handleSortChange}
                                select
                                SelectProps={{native: true}}
                                sx={{m: 1.5}}
                                value={sort}
                            >
                                {sortOptions.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Button
                                variant={'outlined'}
                                sx={{height: '4em', width: '4em'}}
                                onClick={() => setIsOpenFilters(true)}
                            >
                                <RiFilterLine size={24}/>
                            </Button>
                        </Box>
                    </Card>
                    <Card sx={{mt: 2}}>
                        <div ref={startRef}/>
                        {
                            showMap
                                ? <FacilityMap
                                    items={facilities.map(f => ({x: f.cX, y: f.cY}))}
                                    onMapClick={() => {
                                    }}
                                    initFocus={{x: facilities[0]?.cX || 55, y: facilities[0]?.cY || 37}}
                                />
                                : <FiltersLine filters={filters.filtered} onClearFilter={clearFilterByKey}
                                               onClearAll={clearFilters}/>
                        }
                    </Card>

                    <CategoryFilters/>

                    <Card sx={{mt: 2}}>
                        <CardContent>
                            <FacilityList
                                facilities={facilities}
                                onUpdateList={setFacilities}
                                page={page}
                                limit={limit}
                                total={total}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                            />
                        </CardContent>
                    </Card>
                </Container>
            </Box>

            <FiltersMenu
                open={isOpenFilters}
                onClose={() => setIsOpenFilters(false)}
                onClear={clearFilters}
            />
        </>
    )
}

FacilitiesPage.getLayout = page => (
    <AuthGuard>
        <DashboardLayout>
            {page}
        </DashboardLayout>
    </AuthGuard>
)

export default FacilitiesPage
