import { RootState } from "./../store/index"
import { createSelector } from "@reduxjs/toolkit"

export const availFilterCitiesSelector = createSelector(
	(state: RootState) => state.filters.value,
	(filters) => {
        const countries = filters?.all.countries
        const selectedCountryId = filters?.filtered.countryId

        if (!countries || !selectedCountryId) {
			return
		}

		return countries.find(({ id }) => id === selectedCountryId)?.cities
	}
)
