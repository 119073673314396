import { ICountryFull } from "@app/types/geoGuidTypes"
import { Button, Collapse, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { Box } from "@mui/system"
import { ChangeEvent, FC } from "react"
import { useDispatch } from "react-redux"
import { setCityId } from "src/slices/filters"

interface IProps {
	cities?: ICountryFull['cities']
	value?: number | string
}

const CityFilterComponent: FC<IProps> = ({ cities, value }) => {
	const dispatch = useDispatch()

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		if (isNaN(+event.target.value)) {
			dispatch(setCityId(undefined))
		}
		dispatch(setCityId(+event.target.value))
	}

	return (
		<Collapse in={!!cities?.length}>
			<div>
				<Box my={4}>
					<Divider />
				</Box>
				<FormControl component="fieldset">
					<FormLabel component="legend">Выберите город</FormLabel>
					<RadioGroup name="country" value={value} onChange={handleChange}>
						{cities?.map(({ id, title }) => (
							<FormControlLabel key={`city-filter_${id}`} value={id} control={<Radio />} label={title} />
						))}
						<Button onClick={() => dispatch(setCityId(undefined))}>Сбросить город</Button>
					</RadioGroup>
				</FormControl>
			</div>
		</Collapse>
	)
}

export default CityFilterComponent
